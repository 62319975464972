import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Deferred } from "@/services/_base/Deferred";
import ExcelImport from "./excel-import.vue";
import { ModalHelper } from "../modals/ModalHelper";

@Component({
    components: { ExcelImport },
})
export default class ImportExcelFileModal extends Vue {

    @Prop()
    public deferred: Deferred<{ filename: string, filedate: Date, features: server.layerData[] }>;

    @Prop({ required: true })
    public value: { previewMaxRows: number };

    public excelConfirmed(e: { filename: string, filedate: Date, features: server.layerData[] }) {
        this.deferred.resolve(e);
        ModalHelper.HideModal();
    }

    public abort() {
        this.deferred.reject();
        ModalHelper.HideModal();
    }
}
