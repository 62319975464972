import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Deferred } from "@/services/_base/Deferred";
import { engineService, surveysService, layersService, layerDatasService, mediasService } from "@/services";

import { ModalHelper } from "@/components/modals/ModalHelper";

import { mediasStoreGetters, layerDatasStoreGetters, tasksStoreGetters } from "@/store";
import { AlertHelper } from "@/services/_base/AlertHelper";
import { localizeFunction } from "@/filters/localize";
import IssueManage from "@/app_modules/issues/components/issueManage.vue";
import InvalidateLayerData from "@/components/issues/invalidateLayerDataForm.vue";
import ModalIssue from "@/components/modals/modalIssue.vue";

@Component({})
export default class LayerDatasViewModal extends Vue {
    @Prop()
    public deferred: Deferred<server.layerFull>;

    @Prop({ required: true })
    public value: { layer: server.layerFull, boundaryId: string, parentId: string };

    public layer: server.layerFull = null;
    public boundaryId: string = null;
    public parentId: string = null;

    public dataArray: Array<{ boundaryId: string, layerId: string, gid: string, code: string, data: any, supervisorStatus: server.dataSupervisorStatusEnum }> = [];
    public loading: boolean = false;
    public layerDatas: server.layerData[];
    public mediaIds: any = {};

    public sortField: string = "dwnumber";
    public sortOrder: boolean = true;
    public parentLayerData: server.layerData;

    public async created() {
        this.layer = this.value.layer;
        this.boundaryId = this.value.boundaryId;
        this.parentId = this.value.parentId;

        this.loading = true;
        this.layerDatas = await layerDatasService.GetLayerDatas(this.layer.surveyId, this.layer.id, this.boundaryId);
        this.parentLayerData = layerDatasStoreGetters.getLayerData(this.parentId);
        this.dataArray = this.parseLayerData(this.layerDatas.filter((f) => f.parentId === this.parentId));
        if (this.dataArray && this.dataArray.length > 0 && this.dataArray[0].data) {
            const keys = Object.keys(this.dataArray[0].data);
            let key = keys[0];
            if (keys.indexOf(this.sortField) !== -1) {
                key = this.sortField;
            }
            this.setOrder(key);
        }
        this.loading = false;
    }

    public setOrder(field) {
        if (this.sortField === field) {
            this.sortOrder = !this.sortOrder;
        } else {
            this.sortOrder = true;
            this.sortField = field;
        }

        this.dataArray.sort((a, b) => {
            var aParse = isNaN(parseInt(a.data[field])) ? a.data[field] : parseInt(a.data[field]);
            var bParse = isNaN(parseInt(b.data[field])) ? b.data[field] : parseInt(b.data[field]);



            if (aParse < bParse) { return this.sortOrder ? -1 : 1; }
            if (aParse > bParse) { return this.sortOrder ? 1 : -1; }
            return 0;
        });
    }

    public parseLayerData(layerDatas: server.layerData[]): Array<{ boundaryId: string, layerId: string, gid: string, code: string, data: any, supervisorStatus: server.dataSupervisorStatusEnum }> {
        if (!layerDatas || layerDatas.length === 0) { return []; }

        return layerDatas.map((layerData) => {
            const row: any = {};
            const fields = ["code"].concat(this.layer.definitions.sort((a, b) => {
                if (a.order < b.order) { return -1; }
                if (a.order > b.order) { return 1; }
                return 0;
            }).map((m) => m.fieldName).filter((f) => f.indexOf("_missing") < 0));

            for (const key in fields) {
                if (fields.hasOwnProperty(key)) {
                    const field = fields[key];
                    const elem = (layerData.jsonData || {})[field];
                    if(elem !== null && elem !== undefined){
                        row[field] = elem.toString();
                    } else {
                        if(layerData[field]){
                            row[field] = layerData[field];
                        } else{
                            row[field] = "";
                        }
                }
                    // row.push({
                    //     name: field,
                    //     value: (elem !== null && elem !== undefined ? elem : "").toString(),
                    // });
                }
            }
            return {
                boundaryId: layerData.boundaryId,
                layerId: layerData.layerId,
                gid: layerData.gid,
                code: layerData.code,
                data: row,
                supervisorStatus: layerData.supervisorStatus,
            };
        });
    }

    @Watch("dataArray")
    public async loadMedias() {
        for (const key in this.dataArray) {
            if (this.dataArray.hasOwnProperty(key)) {
                const layerDataId = this.dataArray[key].gid;
                if (layerDataId) {
                    const ids = await mediasService.ids("layerData", layerDataId) || [];
                    this.mediaIds[layerDataId] = ids;
                    await Promise.all(
                        ids.map(async (m) => await mediasService.getMedia(m)),
                    );
                }
            }
        }
    }

    public medias(layerDataId: string) {
        return mediasStoreGetters.getMedias("layerData", layerDataId);
    }

    public media(mediaId: string) {
        return `data:image/jpeg;base64,${mediasStoreGetters.getMedia(mediaId)}`;
    }

    public openMedia(media: server.media) {
        const image = new Image();
        image.src = `data:image/jpeg;base64,${media.data}`;

        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    public close() {
        this.deferred.resolve();
        ModalHelper.HideModal();
    }

    public manageIssue(layerData) {
        const task = tasksStoreGetters.getTasks(this.boundaryId).filter((f) => f.status !== server.TasksEnum.completed) || [];
        if (task.length > 0) {
            const obj = {
                layerId: layerData.layerId,
                layerDataId: layerData.gid,
                boundaryId: this.boundaryId,
                creationDate: new Date(),
                latitude: this.parentLayerData.latitude,
                longitude: this.parentLayerData.longitude,
                surveyId: this.parentLayerData.surveyId,
            } as server.issue;
            ModalHelper.ShowModalAsync(IssueManage, obj, "modal-issue").then(async (u) => {
                AlertHelper.showInfo(localizeFunction("save_issue_data"), localizeFunction("issue_saved"), 2000);
            }).catch((err) => {
                console.warn(err);
            });
        } else {
            ModalHelper.ShowModalAsync(ModalIssue, { type: "default", title: localizeFunction("save_issue_data"), message: localizeFunction("not_issue_because_task_is_completed") }, "modal-issue");
        }
    }

    isInvalidated(layerData) {
        if (layerData)
            return layerData.supervisorStatus == server.dataSupervisorStatusEnum.toBeCheckedBySupervisor
        return false
    }

    public invalidateLayerData(layerData) {
        const task = tasksStoreGetters.getTasks(this.boundaryId).filter((f) => f.status !== server.TasksEnum.completed) || [];
        if (task.length > 0) {
            const obj = {
                surveyId: this.parentLayerData.surveyId,
                layerId: layerData.layerId,
                gID: layerData.gid,
                boundaryId: layerData.boundaryId,
                supervisorStatus: server.dataSupervisorStatusEnum.toBeCheckedBySupervisor,
                title: '',
                description: '',
                latitude: this.parentLayerData.latitude,
                longitude: this.parentLayerData.longitude
            } as server.layerdataSupervisor;

            ModalHelper.ShowModalAsync(InvalidateLayerData, obj, "modal-issue").then(async (respose) => {

                AlertHelper.showInfo(localizeFunction("invalidate"), localizeFunction("invalidate_data"), 2000);
                Object.assign(layerData, respose);

            }).catch((err) => {
                console.warn(err);
            });
        } else {
            ModalHelper.ShowModalAsync(ModalIssue, { type: "default", title: localizeFunction("invalidate"), message: localizeFunction("not_invalidate_because_task_is_completed") }, "modal-issue");
        }
    }
}
