import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { defineLocale } from "moment";

@Component({})
export default class NavItem extends Vue {
  @Prop({ default: "nav" })
  name: string;

  @Prop()
  disabled: boolean;

  @Prop()
  url: string;

  @Prop()
  desc: string;

  @Prop()
  iconClass: string;
}
