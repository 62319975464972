import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { Deferred } from "@/services/_base/Deferred";
import ShapeImport from "./shape-import.vue";
import { ModalHelper } from "../modals/ModalHelper";

@Component({
    components: { ShapeImport },
})
export default class ImportShapeFileModal extends Vue {

    @Prop()
    public deferred: Deferred<{ filename: string, filedate: Date, features: server.layerData[] }>;

    @Prop({ required: true })
    public value: { showPreview?: boolean, geometryType?: string, previewMaxRows: number };

    public shapeConfirmed(e: { filename: string, filedate: Date, features: server.layerData[], file: File }) {
        this.deferred.resolve(e);
        ModalHelper.HideModal();
    }

    public abort() {
        this.deferred.reject();
        ModalHelper.HideModal();
    }
}
