import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ModalHelper } from "..";
import IssueManage from "@/app_modules/issues/components/issueManage.vue";
import { localizeFunction } from "@/filters/localize";
import { AlertHelper } from "@/services/_base/AlertHelper";

@Component({})
export default class BoundaryMapPopup extends Vue {
  @Prop()
  public boundary: IBoundary;

  // public manageIssue() {
  //   const obj = {
  //     boundaryId: this.boundary.id,
  //     creationDate: new Date(),
  //     latitude: this.boundary.latitude,
  //     longitude: this.boundary.longitude,
  //     surveyId: this.boundary.surveyId,
  //   } as server.issue;
  //   ModalHelper.ShowModalAsync(IssueManage, obj, "modal-issue").then(async (u) => {
  //     AlertHelper.showInfo(localizeFunction("save_issue_data"), localizeFunction("issue_saved"), 2000);
  //   }).catch((err) => {
  //     console.warn(err);
  //   });
  // }
}
