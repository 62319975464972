import { fillArray, boundariesStoreGetters } from "@/store";
import { Esri, BindArrayToLayer } from "@/esriMap";
import { MapHelper } from "../MapHelper";
import { BoundaryDataMapPopup } from "@/components";
import { localizeFunction } from "@/filters/localize";

export const boundarySymbol = {
    color: [0, 144, 213, 0.3],
    outline: { color: [0, 144, 180, 1], width: 1 },
};

export const highLightBoundarySymbol = {
    color: [255, 238, 6, 0.1],
    outline: { color: [255, 238, 6, 1], width: 1 },
};

export const highLightLayerDataSymbol = {
    color: [255, 255, 0, 0.2],
    outline: { color: [255, 255, 0, 1], width: 2 },
};

export const selectedBoundariesSymbol = {
    color: [0, 121, 193, 0.3],
    outline: { color: [0, 121, 193, 1], width: 1 },
};

export const assignedBoundarySymbol = {
    color: [255, 116, 0, 0.3],
    outline: { color: [182, 82, 0, 1], width: 1 },
};

export const completedBoundarySymbol = {
    color: [0, 150, 0, 0.3],
    outline: { color: [0, 180, 0, 1], width: 1 },
};

export const BOUNDARY_LAYER = "BOUNDARY_LAYER";

export const filteredBoundaries: IBoundary[] = [];

export function filterBoundaries(code: string, parentId: string = null) {

    filteredBoundaries.splice(0, filteredBoundaries.length);
    const boundaries = boundariesStoreGetters.getBoundaries(parentId, null, code, null, null);
    fillArray(filteredBoundaries, boundaries);
    return filteredBoundaries;
}

export async function createBoundaryLayer(): Promise<void> {
    await MapHelper.WaitMapReady();

    let layer = await MapHelper.GetLayerById(BOUNDARY_LAYER);

    // ATTENZIONE: Il layer deve essere creato una sola volta.
    if (layer) {
        return;
    }

    layer = await Esri.Layers.GraphicsLayer({ id: BOUNDARY_LAYER, name: BOUNDARY_LAYER, title: "Boundaries", legendEnabled: false, opacity: 0.8 });
    await MapHelper.addLayer(layer);

    const poupopComponent = MapHelper.CreatePopupTemplate(BoundaryDataMapPopup);
    await BindArrayToLayer(filteredBoundaries, layer.graphics, async (i) => {

        let symbol = boundarySymbol;

        if (i.users.length > 0) {
            symbol = assignedBoundarySymbol;
        }

        if (i.completed) {
            symbol = completedBoundarySymbol;
        }

        if (i.selected) {
            symbol = selectedBoundariesSymbol;
        }

        const clone = Object.assign({}, i);
        return await Esri.Graphic({
            geometry: i.geometryESRI(),
            attributes: clone,
            symbol: await Esri.Symbols.SimpleFillSymbol(symbol),
            popupTemplate: await MapHelper.SetGraphicPopupTemplate(poupopComponent, localizeFunction("boundaries"), (c) => {
                c.$props.boundary = clone;
            }),
        });
    }, true, true, true);
}

// createBoundaryLayer();
