import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { createSurveyStoreGetters, createSurveyStoreActions } from "@/store";

import { uuidv4 } from "@/main";
import { CloneObject } from "@/utils";

@Component({})
export default class MonitoringDefinitions extends Vue {
    @Prop({ default: false })
    public readonly: boolean;

    public manage: server.monitoringStatConfiguration = null;
    public stat: server.statDefinition = null;
    public monitoringDefinitions: server.monitoringStatConfiguration[] = []

    public beforeDestroy() {
        this.clearMonitoringConfig();
    }

    public mounted() {
        this.monitoringDefinitions = this.survey.jsonData || [];
    }

    get survey() {
        return createSurveyStoreGetters.getSurvey() || {} as server.survey;
    }

    get badges() {
        return this.monitoringDefinitions.filter((f) => f.type === "monitoringBadge");
    }

    get charts() {
        return this.monitoringDefinitions.filter((f) => f.type === "monitoringChart");
    }

    get stats() {
        if (!this.survey) {
            return null;
        }
        return createSurveyStoreGetters.getStatsDefinitions(this.survey.id);
    }

    get layers() {
        if (!this.survey) {
            return null;
        }
        return createSurveyStoreGetters.getLayers(this.survey.id);
    }

    get types() {
        return [{ key: "monitoringBadge", value: "monitoringBadge" }, { key: "monitoringChart", value: "monitoringChart" }];
    }

    get chartTypes() {
        return [{ key: "chartColumn", value: "chartColumn" }, { key: "chartPie", value: "chartPie" }];
    }

    @Watch("manage.type")
    public changeTypeWatcher(value: server.monitoringStatType) {
        if (this.manage) {
            if (value === server.monitoringStatType.monitoringChart) {
                this.manage.chartType = server.monitoringStatChartType.chartColumn;
            } else {
                this.manage.chartType = null;
            }
        }
    }

    @Watch("manage.statDefinitionId")
    public statDefinitionIdWatcher() {
        if (this.manage) {
            this.manage.stat = this.stats.find((w) => w.id === this.manage.statDefinitionId) || null;
        }
    }

    @Watch("manage.rows", { deep: true })
    public rowsStatDefinitionIdWatcher(rows: server.monitoringStatConfigurationRow[]) {
        if (rows) {
            rows.forEach((r) => {
                if ((!r.stat && r.statDefinitionId) || r.stat && (r.stat.id !== r.statDefinitionId)) {
                    r.stat = this.stats.find((w) => w.id === r.statDefinitionId) || null;
                }
            });
        }
    }

    public addMonitoringConfig(type) {
        const item = {
            id: uuidv4(),
            surveyId: this.survey.id,
            type: type || server.monitoringStatType.monitoringBadge,
            statDefinitionId: null,
            stat: null,
            chartType: null,
            name: null,
            rows: [{name: null, statDefinitionId: null, value: [] as string[]},
                   {name: null, statDefinitionId: null, value: [] as string[]}] as server.monitoringStatConfigurationRow[],
        } as server.monitoringStatConfiguration;
        this.manage = item;
    }

    public clearMonitoringConfig() {
        this.manage = null;
    }

    public saveMonitoringConfig() {
        const idx = this.monitoringDefinitions.map((m) => m.id).indexOf(this.manage.id);
        if (idx === -1) {
            this.monitoringDefinitions.push(this.manage);
        } else {
            this.monitoringDefinitions.splice(idx, 1, this.manage);
        }
        this.survey.jsonData = this.monitoringDefinitions;
        createSurveyStoreActions.setSurvey(this.survey);
        this.clearMonitoringConfig();
    }

    public editMonitoringConfig(item: server.monitoringStatConfiguration) {
        this.manage = CloneObject(item);
    }

    public removeMonitoringConfig(item: server.monitoringStatConfiguration) {
        const idx = this.monitoringDefinitions.map((m) => m.id).indexOf(item.id);
        if (idx !== -1) {
            this.monitoringDefinitions.splice(idx, 1);
        }
        this.survey.jsonData = this.monitoringDefinitions;
        createSurveyStoreActions.setSurvey(this.survey);
    }

    public getLayer(layerId: string): server.layerFull {
        return this.layers.find((f) => f.id === layerId) || {} as server.layerFull;
    }

    public getLayerDefinition(layerId: string, definitionId: string): server.layerDefinitionFull {
        const layer = this.getLayer(layerId);
        return (layer.definitions || []).find((f) => f.id === definitionId) || {} as server.layerDefinitionFull;
    }
}
