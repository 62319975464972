import { render, staticRenderFns } from "./manageFieldModal.vue?vue&type=template&id=22b5d54b&"
import script from "./manageFieldModal.ts?vue&type=script&lang=ts&"
export * from "./manageFieldModal.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports