import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { createSurveyStoreActions, createSurveyStoreGetters } from "@/store";

import { ModalHelper } from "@/components/modals/ModalHelper";
import ManageFieldModal from "./manageFieldModal.vue";
import ManageValidationRules from "./manageValidationRules.vue";
import ManageStatsDefinitions from "./manageStatsDefinitions.vue";
import { CloneObject } from "@/utils";
import { uuidv4 } from "@/main";
import { layerDatasService } from "@/services";

@Component({ components: { ManageFieldModal, ManageValidationRules, ManageStatsDefinitions } })
export default class ManageLayer extends Vue {
    @Prop({ required: true })
    public value: server.layerFull;

    @Prop({ default: false })
    public readonly: boolean;

    public currentTab = "layer";

    public layer: server.layerFull = null;
    public layerDatas: number = 0;
    public parentLayer: server.layerFull = null;
    public newParentLayer: server.layerFull = null;
    public currentDefinition: server.layerDefinitionFull = null;

    public async created() {
        this.layer = CloneObject(this.value);
        createSurveyStoreActions.setFooterVisible(false);

        const parentLayer = (createSurveyStoreGetters.getLayers(this.layer.surveyId) || []).find((x) => x.subLayers.indexOf(this.layer.id) !== -1);
        if (parentLayer) {
            this.parentLayer = parentLayer;
            this.newParentLayer = parentLayer;
        }

        this.layerDatas = (createSurveyStoreGetters.getLayerDatas(this.layer.id) || []).length;
        if (this.layerDatas <= 0 && (createSurveyStoreGetters.getEdit() || false)) {
            this.layerDatas = await layerDatasService.CountLayerDatas(this.layer.surveyId, this.layer.id);
        }
    }

    get layers() {
        const layers = createSurveyStoreGetters.getLayers(this.layer.surveyId);
        if (layers && layers.length <= 0) { return []; }
        return layers.filter((f) => f.id !== this.layer.id);
    }

    get definitions() {
        return this.layer.definitions.sort((a, b) => {
            if (a.order < b.order) { return -1; }
            if (a.order > b.order) { return 1; }
            return 0;
        });
    }

    public changeDefinitionOrder(definition: server.layerDefinitionFull, value: number) {
        this.currentDefinition = definition;
        const newOrder = definition.order + value;
        this.definitions.forEach((d) => {
            if (newOrder === d.order) {
                d.order -= value;
            }
        });
        definition.order = newOrder <= 1 ? 1 : newOrder;
    }

    public beforeDestroy() {
        createSurveyStoreActions.setFooterVisible(true);
    }

    public complete() {
        this.layer._completed = true;
        this.changeParentLayer();
        createSurveyStoreActions.setLayer(this.layer);
        this.cancel();
    }

    public save() {
        this.layer._completed = false;
        this.changeParentLayer();
        createSurveyStoreActions.setLayer(this.layer);
        this.cancel();
    }

    public cancel() {
        this.$emit("input", null);
    }

    public toggleCardFieldsField(definition: server.layerDefinitionFull) {
        this.currentDefinition = definition;
        if (!this.layer.cardFields) {
            this.layer.cardFields = [];
        }

        const index = this.layer.cardFields.indexOf(definition.fieldName);
        if (index !== -1) {
            this.layer.cardFields.splice(index, 1);
        } else {
            this.layer.cardFields.push(definition.fieldName);
        }
    }

    public setAsDefaultField(definition: server.layerDefinitionFull) {
        this.currentDefinition = definition;
        this.layer.filterField = definition.fieldName;
        this.layer.symbolField = definition.fieldName;
    }

    public addField() {
        ModalHelper.ShowModalAsync(ManageFieldModal, {
            layer: this.layer,
            definition: {
                surveyId: this.layer.surveyId,
                layerId: this.layer.id,
                id: uuidv4(),
                layerName: this.layer.name,
                fieldName: null,
                dataType: null,
                jsonData: [],
                order: null,
                defaultValue: null,
                canBeExplicitlySkipped: false,
                enableMultipleRelation: false,
                _isNewField: true,
            }, isNewField: true, readonly: this.readonly,
        }).then((layer) => {
            this.layer = layer;
        }, (e) => {
            // tslint:disable-next-line:no-console
            console.log(e);
        });
    }

    public editField(definition: server.layerDefinitionFull) {
        this.currentDefinition = definition;
        ModalHelper.ShowModalAsync(ManageFieldModal, { layer: this.layer, definition, isNewField: (definition._isNewField || false), readonly: this.readonly }).then((layer) => {
            this.layer = layer;
        }, (e) => {
            // tslint:disable-next-line:no-console
            console.log(e);
        });
    }

    public removeField(definition: server.layerDefinitionFull) {
        const index = this.layer.definitions.indexOf(definition);
        if (index >= 0) {
            this.layer.definitions.splice(index, 1);

            const cardFieldIndex = this.layer.cardFields.indexOf(definition.fieldName);
            if (cardFieldIndex !== -1) {
                this.layer.cardFields.splice(cardFieldIndex, 1);
                if (!this.layer.cardFields || this.layer.cardFields.length <= 0) {
                    this.layer.cardFields.push(this.layer.definitions[0].fieldName);
                }
            }

            if (this.layer.filterField === definition.fieldName || this.layer.symbolField === definition.fieldName) {
                const defaultField = this.layer.definitions[0].fieldName;
                this.layer.filterField = defaultField;
                this.layer.symbolField = defaultField;
            }
        }
    }

    private changeParentLayer() {
        if ((this.parentLayer || {} as server.layerFull).id !== (this.newParentLayer || {} as server.layerFull).id) {
            if (this.parentLayer) {
                const idx = this.parentLayer.subLayers.indexOf(this.layer.id);
                this.parentLayer.subLayers.splice(idx, 1);
                createSurveyStoreActions.setLayer(this.parentLayer);
            }
            if (this.newParentLayer) {
                if (!this.newParentLayer.subLayers) {
                    this.newParentLayer.subLayers = [];
                }
                this.newParentLayer.subLayers.push(this.layer.id);
                createSurveyStoreActions.setLayer(this.newParentLayer);
                this.layer.orderIndex = Math.max.apply(null, this.newParentLayer.subLayers.map((l) => {
                    return (createSurveyStoreGetters.getLayer(l) || { orderIndex: this.newParentLayer.orderIndex }).orderIndex;
                })) + 1;
            }
        }
    }
}
