import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";

@Component({})
export default class CustomPagination extends Vue {
  @Prop({ required: true, default: 0 })
  value: number;

  @Prop({ required: false, default: 10 })
  itemsPerPage: number;

  @Prop({ required: false, default: 1 })
  totalPages: number;

  @Prop({ required: false, default: 3 })
  maxVisiblePages: number;

  currentPage: number = this.value;
  @Watch("currentPage", { deep: true })
  async currentPageChange(n, o) {
    if (n != o) this.$emit("input", n);
  }

  get totalPagesArrays(): number[] {
    let result = [];
    for (let index = 0; index < this.totalPages; index++) {
      result.push(index);
    }
    return result;
  }

  get pages() {
    let filteredPages = this.filteredPages;
    let pages = this.totalPagesArrays as any[];
    if (filteredPages)
      pages = [
        0,
        filteredPages[0] - 1 === 1 ? 1 : "...",
        ...filteredPages,
        filteredPages[filteredPages.length - 1] + 1 === this.totalPages - 2
          ? this.totalPages - 2
          : "...",
        this.totalPages - 1
      ];
    return [this.currentPage - 1, ...pages, this.currentPage + 1];
  }

  get filteredPages() {
    let diff = this.maxVisiblePages / 2;
    let toFilterPages = this.totalPagesArrays.slice(2, -2);

    if (toFilterPages.length > this.maxVisiblePages) {
      let diffFirst = this.currentPage - toFilterPages[0];
      let diffLast = this.currentPage - toFilterPages[toFilterPages.length - 1];

      if (diffFirst < diff) {
        return toFilterPages.slice(0, this.maxVisiblePages);
      } else if (diffLast >= -diff) {
        return toFilterPages.slice(-this.maxVisiblePages);
      } else {
        return toFilterPages.filter(page => {
          let diffPage = this.currentPage - page;

          return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff;
        });
      }
    }
    return null;
  }

  get buttons() {
    return this.pages.map((page, key) => {
      return {
        page,
        active: page === this.currentPage,
        disabled: this.disabled(page, key),
        html: this.html(page, key)
      };
    });
  }

  html(page, key) {
    if (key === 0) {
      return '<i class="fa fa-angle-left"></i>';
    }

    if (key === this.pages.length - 1) {
      return '<i class="fa fa-angle-right"></i>';
    }

    if (page === "...") {
      return page;
    }

    return page + 1 + "";
  }

  disabled(page, key) {
    return (
      (key === 0 && this.currentPage === 0) ||
      (key === this.pages.length - 1 &&
        this.currentPage === this.totalPages - 1) ||
      page === "..."
    );
  }
}
