import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { createSurveyStoreActions, createSurveyStoreGetters } from "@/store";

import { uuidv4 } from "@/main";
import { ModalHelper } from "@/components";
import Modal from "@/components/modals/modal.vue";
import { localizeFunction } from "@/filters/localize";

@Component({})
export default class QualityControlDefinitions extends Vue {
    @Prop({ default: false })
    public readonly: boolean;

    public layer: server.layerFull = null;
    public definitions: server.layerDefinitionFull[] = [];
    public currentDefinition: server.layerDefinitionFull = null;

    public manage: server.qualityDefinition = null;

    public checkedNames: any[] = [];


    public mounted() {
        this.layer = (this.layers || [null])[0];
    }

    public beforeDestroy() {
        this.clearValidationQuality();
    }

    get isReadyToComplete(): boolean {
        return createSurveyStoreGetters.getValid();
    }

    set isReadyToComplete(value: boolean) {
        createSurveyStoreActions.setValid(value);
    }

    get survey() {
        return createSurveyStoreGetters.getSurvey();
    }

    get qualityDefinitions() {
        if (!this.survey) {
            return null;
        }
        return createSurveyStoreGetters.getQualityDefinitions(this.survey.id).map(m => {
            m["childs"] = this.getQaulityChilds(m.id) || [];
            return m;
        });
    }

    @Watch("qualityDefinitions")
    public qualityDefinitionsWatcher(qualities) {
        this.isReadyToComplete = this.qualityDefinitions.filter((f) => f.action === "CheckChildRule" && f["childs"].length <= 0).length === 0;
    }

    get parentQualityDefinitions(): server.qualityDefinition[] {
        if (!this.qualityDefinitions) {
            return null;
        }
        return this.qualityDefinitions.filter((f) => f.action === "CheckChildRule" || (f.action !== "CheckChildRule" && !f.parentId));
    }

    get layers() {
        if (!this.survey) {
            return null;
        }
        return createSurveyStoreGetters.getLayers(this.survey.id);
    }

    @Watch("layer")
    public layerWatcher(n: server.layerFull, o: server.layerFull) {
        if (!n) {
            this.definitions = [];
            return;
        }
        if (n !== o) {
            this.definitions = n.definitions.sort((a, b) => {
                if (a.order < b.order) { return -1; }
                if (a.order > b.order) { return 1; }
                return 0;
            });
        }
    }

    get availableOperators() {
        return [
            { key: "Equals", value: "equals" },
            { key: "NotEquals", value: "notEquals" },
            { key: "Greater", value: "greater" },
            { key: "Less", value: "less" },
            { key: "GreaterOrEquals", value: "greaterorequals" },
            { key: "LessOrEquals", value: "lessorrequals" },
            { key: "ContainedIn", value: "containedin" },
        ];
    }

    get availableActions() {
        return [
            { key: "SetToCheck", value: "settocheck" },
            { key: "SetRemoved", value: "setremoved" },
            { key: "CheckChildRule", value: "checkchildrule" },
        ];
    }

    get availableCheckTypes() {
        return [
            { key: "GeometryArea", value: "geometryarea" },
            { key: "GeometryPerimeter", value: "geometryperimeter" },
            { key: "LayerDefinition", value: "layerdefinition" },
            { key: "ChildsCount", value: "childscount" },
            { key: "AreaRatioDeviation", value: "arearatiodeviation" },
        ];
    }

    @Watch("manage.checkType")
    public checkTypeWatcher(n: string, o: string) {
        if (this.manage) {
            this.manage.definitionId = null;
        }
    }

    @Watch("manage.action")
    public actionWatcher(n: string, o: string) {
        if (this.manage && n && n === "CheckChildRule") {
            this.manage.parentId = null;
        }
    }

    @Watch("manage.definitionId")
    public filterDefinitionIdWatcher(value: string) {
        this.checkedNames = [];
        if (value) {
            this.currentDefinition = this.layer.definitions.find((f) => f.id === value);
        } else {
            this.currentDefinition = null;
        }
    }

    public addValidationQuality(parent: server.qualityDefinition) {
        const quality = {
            id: uuidv4(),
            surveyId: this.layer.surveyId,
            layerId: this.layer.id,
            name: null,
            description: null,
            checkType: server.qualityCheckTypes.geometryArea,
            operator: server.qualityCheckOperators.equals,
            action: server.qualityActions.setToCheck,
            parentId: parent ? parent.id : null,
            definitionId: null,
            value: null,
        };
        this.manage = quality;
    }

    public clearValidationQuality() {
        this.manage = null;
    }

    public saveValidationQuality() {
        this.manage.layerId = this.layer.id;

        if (this.manage.checkType === "LayerDefinition" && this.manage.operator === "ContainedIn" && this.currentDefinition && this.currentDefinition.dataType === "jsondata") {
            this.manage.value = JSON.stringify(this.checkedNames);
        } else {
            this.manage.value = JSON.stringify(this.manage.value);
        }

        createSurveyStoreActions.setQualityDefinition(this.manage);
        this.clearValidationQuality();
    }

    public editValidationQuality(quality: server.qualityDefinition) {
        this.manage = quality;
    }

    public removeValidationQuality(quality: server.qualityDefinition) {
        if (quality["childs"] && quality["childs"].length > 0) {
            ModalHelper.ShowModalAsync(Modal, { type: "default", title: localizeFunction("delete_quality_definition"), message: localizeFunction("delete_quality_definition_with_child") });
        } else {
            createSurveyStoreActions.removeQualityDefinitions(quality);
        }
    }

    public getLayer(layerId: string): server.layerFull {
        return this.layers.find((f) => f.id === layerId) || {} as server.layerFull;
    }

    public getLayerDefinition(layerId: string, definitionId: string): server.layerDefinitionFull {
        const layer = this.getLayer(layerId);
        return (layer.definitions || []).find((f) => f.id === definitionId) || {} as server.layerDefinitionFull;
    }

    public getQaulityChilds(id: string) {
        const a = createSurveyStoreGetters.getQualityDefinitions(this.survey.id).filter((f) => f.parentId === id) || [];
        return a;
    }
}
