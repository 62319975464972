import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { getStoreState, boundariesStoreGetters } from "@/store";

import { localizeFunction } from "@/filters/localize";

@Component({})
export default class IssueCard extends Vue {
  @Prop()
  issue: server.issue;

  get boundary(){
    return boundariesStoreGetters.getBoundaryAutocomplete(this.issue.boundaryId);
  }

  getUserData(issue: server.issue) {
    let user = getStoreState().users.users.find(u => u.userName == issue.userName);
    if (user) 
      return `${user.description} (${this.removeAppPrefix(user.userName)})`;
    return localizeFunction("not_assigned");
  }

  removeAppPrefix(email: string) {
    return email.replace(/.*::/g, '');
  }
}