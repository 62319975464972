import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Deferred } from "@/services/_base/Deferred";
import { ModalHelper } from "@/components/modals/ModalHelper";

import LayerColorPicker from "@/components/layer-color-picker/layer-color-picker.vue";

import { CloneObject } from "@/utils";
import { createSurveyStoreGetters } from "@/store";

@Component({ components: { LayerColorPicker } })
export default class ManageFieldModal extends Vue {
    @Prop()
    public deferred: Deferred<server.layerFull>;

    @Prop({ required: true })
    public value: { layer: server.layerFull, definition: server.layerDefinitionFull, isNewField?: boolean, readonly?: boolean };

    public field: server.layerDefinitionFull = null;
    public layer: server.layerFull = null;
    public isNewField: boolean = false;
    public readonly: boolean = false;
    public availableSymbols: Array<{ symbol: any, value: string }> = [];
    public defaultSymbol: { symbol: any, value: string } = null;

    public newFieldDefaultValue: { symbol: any, value: string } = null;

    private defaultFillColor = [0, 144, 180, 0.8];
    private defaultOutlineColor = [0, 144, 180, 1];

    public created() {
        this.field = CloneObject(this.value.definition);
        this.field.defaultValue = this._getDefaultValue();
        this.layer = this.value.layer;
        this.isNewField = this.value.isNewField || false;
        this.readonly = this.value.readonly || false;
        this.field.order = this.makeOrder;
        if (this.field.dataType === "jsondata") {
            this.availableSymbols = this._getAvailableSymbols();
            this.defaultSymbol = this._getAvailableDefaultSymbol();
        }
    }

    get layerDatas() {
        return createSurveyStoreGetters.getLayerDatas(this.layer.id);
    }

    get makeOrder() {
        if (!this.field.order || (this.field.order && (this.layer.definitions || []).filter((f) => f.order === this.field.order).length > 1)) {
            let maxOrder = 0;
            const defs = this.layer.definitions.map((m) => m.order);
            if(defs.length > 0) {
                maxOrder = Math.max(...defs);
            }
            return (maxOrder + 1);
        }
        return this.field.order;
    }

    get fieldTypesAvailable() {
        if (!this.field.dataType || this.isNewField) {
            return ["string", "text", "jsondata", "number", "boolean", "datetime", "detection_longitude", "detection_latitude", "detection_gps_accuracy", "detection_gps_timestamp"];
        }
        if (this.field.dataType === "string" || this.field.dataType === "text" || this.field.dataType === "jsondata") {
            return ["string", "text", "jsondata"];
        }
        if (this.field.dataType === "number" || this.field.dataType === "detection_longitude" || this.field.dataType === "detection_latitude" || this.field.dataType === "detection_gps_accuracy") {
            const values = ["number", "detection_longitude", "detection_latitude", "detection_gps_accuracy"];
            if (this.layerDatas && this.layerDatas.length > 0 && this.layerDatas.filter((x) => x.jsonData[this.field.fieldName] !== 0 && x.jsonData[this.field.fieldName] !== 1).length === 0) {
                values.push("boolean");
            }
            return values;
        }
        if (this.field.dataType === "boolean") {
            return ["number", "boolean"];
        }
        if (this.field.dataType === "datetime" || this.field.dataType === "detection_gps_timestamp" ) {
            return ["datetime", "detection_gps_timestamp"];
        }
        return [this.field.dataType];
    }

    public removeFieldDefaultValue(availableSymbol: { symbol: any, value: string }) {
        const indexField = this.field.jsonData.indexOf(availableSymbol.value);
        if (indexField !== -1) {
            this.field.jsonData.splice(indexField, 1);
        }

        const indexSymbol = this.availableSymbols.map((m) => m.value).indexOf(availableSymbol.value);
        if (indexSymbol !== -1) {
            this.availableSymbols.splice(indexSymbol, 1);
        }

        if (this.defaultSymbol && this.defaultSymbol.value === availableSymbol.value) {
            this.defaultSymbol = this._getAvailableDefaultSymbol();
        }
    }

    public addFieldDefaultValue() {
        this.newFieldDefaultValue = {
            value: null, symbol: this._getDefaultSymbol(),
        };
    }

    public saveFieldDefaultValue() {
        this.field.jsonData.push(this.newFieldDefaultValue.value);

        const indexSymbol = this.availableSymbols.map((m) => m.value).indexOf(this.newFieldDefaultValue.value);
        if (indexSymbol !== -1) {
            this.availableSymbols.splice(indexSymbol, 1, this.newFieldDefaultValue);
        } else {
            this.availableSymbols.push(this.newFieldDefaultValue);
        }

        if (!this.defaultSymbol) {
            this.defaultSymbol = this._getAvailableDefaultSymbol();
        }

        this.cleanFieldDefaultValue();
    }

    public cleanFieldDefaultValue() {
        this.newFieldDefaultValue = null;
    }

    public checkIfSameSymbol(defaultSymbol, availableSymbol) {
        return JSON.stringify(defaultSymbol) === JSON.stringify(availableSymbol);
    }

    public save() {
        if (!this.layer.definitions) {
            this.layer.definitions = [];
        }

        const index = this.layer.definitions.map((m) => m.id).indexOf(this.field.id);
        if (index !== -1) {
            this.layer.definitions.splice(index, 1, this.field);
        } else {
            this.layer.definitions.push(this.field);
        }

        if (this.layer.geometryType != null && this.field.dataType === "jsondata" && this.layer.symbolField === this.field.fieldName) {
            this.layer.symbolDefinition = this.availableSymbols;
            this.layer.defaultSymbolDefinition = this.defaultSymbol;
        }

        if (this.field.dataType === "boolean" && this.layerDatas && this.layerDatas.length > 0) {
            this.layerDatas.forEach((f) => f.jsonData[this.field.fieldName] = f.jsonData[this.field.fieldName] === 1 ? true : f.jsonData[this.field.fieldName] === 0 ? false : this.field.defaultValue);
        }

        if (!this.layer.filterField) {
            this.layer.filterField = this.field.fieldName;
        }

        if (!this.layer.symbolField) {
            this.layer.symbolField = this.field.fieldName;
        }

        if (!this.layer.cardFields || this.layer.cardFields.length <= 0) {
            if (!this.layer.cardFields) {
                this.layer.cardFields = [];
            }
            this.layer.cardFields.push(this.field.fieldName);
        }

        this.deferred.resolve(this.layer);
        ModalHelper.HideModal();
    }

    public abort() {
        this.deferred.reject();
        ModalHelper.HideModal();
    }

    @Watch("field.dataType")
    private _fieldDataTypeWatcher() {
        this.field.defaultValue = this._getDefaultValue();
        if (this.field.dataType === "jsondata") {
            this.availableSymbols = this._getAvailableSymbols();
            this.defaultSymbol = this._getAvailableDefaultSymbol();
        } else {
            this.availableSymbols = [];
            this.defaultSymbol = null;
        }
    }

    @Watch("availableValues")
    private _availableValuesWatcher() {
        if (!this.defaultSymbol) {
            this.defaultSymbol = this._getAvailableDefaultSymbol();
        }
    }

    private _getDefaultValue() {
        return null;
    }

    private _getDefaultSymbol() {
        const symbol = {
            type: "simple-fill",
            color: this.defaultFillColor,
            outline: { color: this.defaultOutlineColor, width: 2 },
        } as any;

        if (this.layer.geometryType === "point") {
            symbol.type = "simple-marker";
        }
        return CloneObject(symbol);
    }

    private _getAvailableSymbols(): Array<{ symbol: any, value: string }> {
        const result: Array<{ symbol: any, value: string }> = [];
        if (this.field && this.field.jsonData) {
            for (const key in this.field.jsonData) {
                if (this.field.jsonData.hasOwnProperty(key)) {
                    const element = this.field.jsonData[key];
                    let symbol = this._getLayerFieldSymbol(element);
                    if (!symbol) {
                        symbol = { value: element, symbol: this._getDefaultSymbol() };
                    }
                    result.push(symbol);
                }
            }
        }
        return result;
    }

    private _getAvailableDefaultSymbol(): { symbol: any, value: string } {
        let symbol = this.layer.defaultSymbolDefinition;
        if (!symbol && this.availableSymbols.length > 0) {
            symbol = this.availableSymbols[0];
        }
        return symbol;
    }

    private _getLayerFieldSymbol(value) {
        if (!this.layer.symbolDefinition || this.layer.symbolDefinition.length <= 0) {
            return null;
        }

        const index = this.layer.symbolDefinition.map((m) => m.value).indexOf(value);
        if (index === -1) {
            return null;
        }

        return CloneObject(this.layer.symbolDefinition[index]);
    }
}
