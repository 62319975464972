import { MessageService, Factory } from "@/services/_base";

export default {
    computed: {
        isVisible: function () {
            return this.currentView != null;
        },
    },
    props: {
        containerName: { default: "modal" },
    },
    data: function () {
        return {
            currentView: null,
            deferred: null,
            model: null,
        };
    },
    created() {
        Factory.Get(MessageService).subscribeToChannel("show-" + this.containerName, args => {
            if (this.informHmi) {
                Factory.Get(MessageService).publishToChannel("HMI-show-modal");
            }
            this.currentView = args[0];
            if (args[1]) {
                this.deferred = args[1];
            }
            if (args[2]) {
                this.model = args[2];
            }
        });

        Factory.Get(MessageService).subscribeToChannel("hide-" + this.containerName, args => {

            if (this.informHmi) {
                Factory.Get(MessageService).publishToChannel("HMI-hide-modal", 300);
            }
            this.currentView = null;
        });
    },
};
