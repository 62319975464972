import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Deferred } from "@/services/_base/Deferred";
import { ModalHelper } from "..";

@Component({})
export default class ModalIssue extends Vue {

  @Prop()
  public deferred: Deferred<boolean>;

  @Prop({ required: true })
  public value: { title: string, type: "defualt" | "yesNo" | "confirmCancel", component: any, model: any, message: string };

  public close() {
    this.deferred.resolve(false);
    ModalHelper.HideModal("modal-issue");
  }

  public confirm(){
    this.deferred.resolve(true);
    ModalHelper.HideModal("modal-issue");
  }
}
