import Vue from "vue";
import Component from "vue-class-component";
import { Esri,  Measure, MeasureParameters } from "@/esriMap";
import { MapHelper } from "@/app_modules/MapHelper";
import { localizeFunction } from "@/filters/localize";

import Persistance from "vue-storage-decorator";
import { addOrtophotoTileLayer, addParcelFeatureLayer } from "../module";
import { Widgets } from "@/esriMap/src/Widgets";

const Persist = Persistance("mapConfiguration");

@Component({})
export default class Map extends Vue {
  private map: __esri.Map;
  private mapView: __esri.MapView;
  private measureTool: any;

  private basemap: string = "topo";
  private center = [42, 12];

  public mounted() {
    this.InitMap();
  }

  public beforeDestroy() {
    MapHelper.clearMap();
  }

  private localizeFunction(value) {
    return localizeFunction(value);
  }

  private async InitMap() {
    this.map = await Esri.Map({ basemap: "hybrid" } as __esri.MapProperties);

    this.mapView = await Esri.Views.MapView({
      map: this.map,
      container: "map",
      constraints: {
        snapToZoom: false,
        minZoom: 4,
        maxScale: 282.124294,
      },
      zoom: 8,
      center: [12.833333, 42.833333],
    } as __esri.MapViewProperties);

    this.mapView.when(async () => {
      MapHelper.setCurrentMap(this);
      addOrtophotoTileLayer();
      // addParcelFeatureLayer();
    });

    await this.addTools();
  }

  private async addTools() {
    const $this = this;

    Esri.WidgetsFactory.Search(this.mapView);

    const layerlist = await Esri.WidgetsFactory.LayerList(this.mapView, "top-left", true);
    // layerlist.on("trigger-action", (event) => {
    //   if (event.action.action) {
    //     try {
    //       event.action.action();
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   }
    // });

    const widgets = new Widgets();
    const result = await widgets.BasemapGallery({ view: this.mapView, container: document.createElement("div") });
    const resultbutton = await widgets.Expand({ content: (result as any).domNode, view: this.mapView, expandIconClass: "esri-icon-basemap", autoCollapse: true } as __esri.ExpandProperties);
    this.mapView.ui.add(resultbutton, { position: "top-left" } as any);

    this.measureTool = new Measure(new MeasureParameters(this.mapView));
    const measureAreaToolButton = document.getElementById("measure-area-tool");
    if (measureAreaToolButton) {
      measureAreaToolButton.style.display = "flex";
      measureAreaToolButton.addEventListener("click", () => { $this.measureTool.MeasureArea(); });
      this.mapView.ui.add("measure-area-tool", "top-left");
    }

    const measureDistanceToolButton = document.getElementById("measure-distance-tool");
    if (measureDistanceToolButton) {
      measureDistanceToolButton.style.display = "flex";
      measureDistanceToolButton.addEventListener("click", () => { $this.measureTool.MeasurePerimeter(); });
      this.mapView.ui.add("measure-distance-tool", "top-left");
    }
  }

}
