import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { createSurveyStoreActions, createSurveyStoreGetters } from "@/store";

import { ModalHelper } from "@/components";
import Modal from "@/components/modals/modal.vue";

import { uuidv4 } from "@/main";
import { localizeFunction } from "@/filters/localize";

@Component({})
export default class ManageStatsDefinitions extends Vue {
    @Prop({ required: true })
    public layer: server.layerFull;

    @Prop({ default: false })
    public readonly: boolean;

    public filterDefinition: server.layerDefinitionFull = null;
    public groupDefinition: server.layerDefinitionFull = null;
    public calcDefinition: server.layerDefinitionFull = null;

    public manage: server.statDefinition = null;

    public beforeDestroy() {
        this.clearValidationStat();
    }

    get survey() {
        return createSurveyStoreGetters.getSurvey();
    }

    get statDefinitions() {
        return createSurveyStoreGetters.getStatsDefinitions(this.layer.surveyId, this.layer.id);
    }

    get availableGroupTypes() {
        return [
            { key: "NoGroup", value: "noGroup" },
            { key: "GroupByValue", value: "groupByValue" },
        ];
    }

    get availableCalcModes() {
        return [
            { key: "Sum", value: "sum" },
            { key: "Mean", value: "mean" },
            { key: "Count", value: "count" },
        ];
    }

    get validStat(): boolean {
        return !!this.manage.name;
    }

    @Watch("manage.groupType")
    public groupTypeWatcher(value) {
        if (value && value === "NoGroup") {
            this.manage.groupDefinitionId = null;
        }
    }

    @Watch("manage.calcMode")
    public calcModeWatcher(value) {
        if (value && value === "Count") {
            this.manage.calcDefinitionId = null;
        }
    }

    @Watch("manage.groupDefinitionId")
    public groupDefinitionIdWatcher(value) {
        if (value) {
            this.groupDefinition = this.layer.definitions.find((f) => f.id === value);
        } else {
            this.groupDefinition = null;
        }
    }

    @Watch("manage.calcDefinitionId")
    public calcDefinitionIdWatcher(value) {
        if (value) {
            this.calcDefinition = this.layer.definitions.find((f) => f.id === value);
        } else {
            this.calcDefinition = null;
        }
    }

    @Watch("manage.filterDefinitionId")
    public filterDefinitionIdWatcher(value: string) {
        if (value) {
            this.filterDefinition = this.layer.definitions.find((f) => f.id === value);
        } else {
            this.filterDefinition = null;
        }
    }

    public getDefinitionName(definitionId) {
        return (this.layer.definitions.find((f) => f.id === definitionId) || {} as server.layerDefinitionFull).fieldName;
    }

    public addValidationStat() {
        const stat: server.statDefinition = {
            id: uuidv4(),
            surveyId: this.layer.surveyId,
            layerId: this.layer.id,
            date: new Date(),
            name: null,
            groupType: server.statGroupTypes.noGroup,
            calcMode: server.statCalcModes.count,
            groupDefinitionId: null,
            calcDefinitionId: null,
            filterDefinitionId: null,
            filterDefinitionValue: null,
        };
        this.manage = stat;
    }

    public clearValidationStat() {
        this.manage = null;
    }

    public saveValidationStat() {
        this.manage.filterDefinitionValue = JSON.stringify(this.manage.filterDefinitionValue);
        createSurveyStoreActions.setStatsDefinition(this.manage);
        this.clearValidationStat();
    }

    public removeValidationStat(stat: server.statDefinition) {
        let statDefinitionIds: string[] = [];
        if (this.survey && this.survey.jsonData) {
            const badgeDefinitions = this.survey.jsonData.filter((f) => f.type === server.monitoringStatType.monitoringBadge) || [];
            const chartDefinitions = this.survey.jsonData.filter((f) => f.type === server.monitoringStatType.monitoringChart) || [];

            statDefinitionIds = chartDefinitions.filter((w) => w.statDefinitionId).map((f) => f.statDefinitionId) || [];
            if (badgeDefinitions && badgeDefinitions.length > 0) {
                badgeDefinitions.forEach((b) => {
                    b.rows.forEach((r) => {
                        if (r.statDefinitionId) {
                            statDefinitionIds.push(r.statDefinitionId);
                        }
                    });
                });
            }
        }

        if (statDefinitionIds.length <= 0 || statDefinitionIds.indexOf(stat.id) === -1) {
            createSurveyStoreActions.removeStatsDefinitions(stat);
        } else {
            ModalHelper.ShowModalAsync(Modal, { type: "confirmCancel", title: localizeFunction("delete_stat_definition"), message: localizeFunction("delete_stat_definition_message") }).then(async (confirmed) => {
                if (confirmed) {
                    this.survey.jsonData = this.survey.jsonData.filter((f) => !(f.statDefinitionId === stat.id ||  (f.rows.filter((m) => m.statDefinitionId === stat.id).length > 0)))
                    createSurveyStoreActions.removeStatsDefinitions(stat);
                }
            });
        }
    }
}
