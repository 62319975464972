import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import XLSX from "xlsx";
import { localizeFunction } from "@/filters/localize";

import LoadMore from "@/components/loadMore/loadMore.vue";

@Component({ components: { LoadMore } })
export default class ExcelImport extends Vue {
  @Prop({ default: -1, required: false })
  private previewMaxRows: number

  public isLoading = false;
  public tableRows: any[] = [];
  public fileImportError: string = null;

  private file: File = null;
  private excelFile: File = null;

  get labelCreateFieldInstructions() {
    return localizeFunction("create_field_instructions");
  }

  public async uploadFile(event) {
    this.excelFile = event.target.files[0];
    if (!this.excelFile) { return; }

    this.isLoading = true;
    setTimeout(async () => {
      const fileData = await this.readAsArrayBuffer(this.excelFile);
      this.parseFiles(fileData);
      this.isLoading = false;
    }, 200);
  }


  skip: number = 0;
  take: number = 20;

  private parseFiles(data) {
    const fixedData = this.fixData(data);
    const workbook = XLSX.read(btoa(fixedData), { type: "base64" });

    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    let rows = XLSX.utils.sheet_to_json(sheet) as any[];
    const str = JSON.stringify({
      type: "FeatureCollection",
      features: rows.map(r => { 
        return {
          type: "Feature",
          geometry: null,
          properties: r
        }
      })
    });
    const bytes = new TextEncoder().encode(str);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8"
    });

    this.file = new File([blob], this.excelFile.name, { type: "application/json;charset=utf-8", lastModified: this.excelFile.lastModified })

    if (this.previewMaxRows > 0 && rows.length > this.previewMaxRows) {
      rows = rows.slice(0, this.previewMaxRows);
    }

    if ((rows || []).length <= 0) {
      this.fileImportError = "features_length_as_empty";
      this.clear();
    } else {
      this.tableRows = rows;
      this.features = rows.slice(0, this.take) || [];
    }
  }

  private pFeatures: any[] = [];
  public get features(): any[] {
    return this.pFeatures;
  }
  public set features(v: any[]) {
    this.pFeatures = this.features.concat(v) || [];
  }

  public onLoadMoreItems() {
    this.skip += this.take;
    this.features = this.tableRows.slice(this.skip, (this.skip + this.take)) || [];
  }

  private fixData(data) {
    let o = "";
    let l = 0;
    let w = 10240;
    for (l; l < data.byteLength / w; ++l) {
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(data.slice(l * w, l * w + w)),
      );
    }
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
    return o;
  }

  public confirm() {
    this.isLoading = true;

    setTimeout(() => {
      this.$emit("confirm", {
        file: this.file,
        filename: this.excelFile.name,
        filedate: new Date(this.excelFile.lastModified),
        features: this.tableRows,
      });
      this.skip = 0;
      this.clear();
    }, 200);
  }

  public clear() {
    this.tableRows = [];
    this.features = [];
    (this.$refs.excelFileInput as HTMLInputElement).value = "";
  }

  private async readAsArrayBuffer(file: File) {
    return new Promise<any>((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        res(e.target.result);
      };
      reader.onabort = (e) => {
        rej(e);
      };
      reader.onerror = (e) => {
        rej(e);
      };
      reader.readAsArrayBuffer(file);
    });
  }

}
