import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { usersStoreGetters, getStoreState, layersStoreGetters, layerDatasStoreGetters, boundariesStoreGetters } from "@/store";
import { ModalHelper } from "@/components";
import { Deferred } from "@/services/_base/Deferred";
import { localizeFunction } from "@/filters/localize";
import { layerDatasService, boundariesService } from "@/services";

@Component({ components: {} })
export default class InvalidateLayerDataForm extends Vue {
  @Prop()
  public deferred: Deferred<server.layerData>;

  @Prop({ required: true })
  public value: server.layerdataSupervisor;

  public manage: server.layerdataSupervisor = {} as server.layerdataSupervisor;

  get isAdmin() {
    return usersStoreGetters.currentUser().isAdmin;
  }

  public mounted() {
    this.valueChange(this.value);
  }

  @Watch("value")
  public valueChange(value) {
    const valueChanged = value || {} as server.layerdataSupervisor;

    this.manage = Object.assign({}, valueChanged);
  }

  public reset() {
    this.valueChange(this.value);
  }

  public abort() {
    ModalHelper.HideModal("modal-issue");
    if (this.deferred) { this.deferred.reject(); }
  }

  public async save() {
    this.manage.title = await this.getTitle();
    const layerdataSupervisor = await layerDatasService.setSupervisorMessage(
      this.manage.surveyId,
      this.manage.layerId,
      this.manage.gID,
      this.manage,
    );
    ModalHelper.HideModal("modal-issue");
    if (this.deferred) { this.deferred.resolve(layerdataSupervisor); }
  }

  public async getTitle() {
    const layer = layersStoreGetters.getLayer(this.manage.surveyId, this.manage.layerId);
    const layerData = layerDatasStoreGetters.getLayerData(this.manage.gID);
    const boundary = await boundariesService.GetBoundary(this.manage.surveyId, this.manage.boundaryId);
    let title = `${localizeFunction("the data")} ${localizeFunction(layer.name)} ${layerData.code} ${localizeFunction("of")} ${localizeFunction("enumeration_area")} ${boundary.code} ${localizeFunction("has_been_invalidated")}`;
    return title
  }

  public getUserData(issue: server.issue) {
    const user = getStoreState().users.users.find((u) => u.userName === issue.userName);
    if (user) {
      return `${user.description} (${this.removeAppPrefix(user.userName)})`;
    }
    return localizeFunction("not_assigned");
  }

  public removeAppPrefix(email: string) {
    return email.replace(/.*::/g, "");
  }
}
