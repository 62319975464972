import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { getStoreState } from "@/store/app.store";
import { mediasService, layerDatasService } from "@/services";
import { mediasStoreGetters } from "@/store/medias.store";
import { localizeFunction } from "@/filters/localize";
import { usersStoreGetters, layersStoreGetters, tasksStoreGetters } from "@/store";
import { ModalHelper } from "..";
import LayerDatasViewModal from "@/app_modules/layers/components/layerDatasViewModal.vue";
import IssueManage from "@/app_modules/issues/components/issueManage.vue";
import { AlertHelper } from "@/services/_base/AlertHelper";
import { MapHelper } from "@/app_modules/MapHelper";
import { Esri } from "@/esriMap";
import InvalidateLayerData from "@/components/issues/invalidateLayerDataForm.vue";
import ModalIssue from "@/components/modals/modalIssue.vue";

@Component({})
export default class LayerDataMapPopup extends Vue {
  @Prop()
  public layerData: ILayerData;

  public typeId: string = null;

  public mediaIds: string[] = [];

  public relatedLayerDataCounter: any = {};
  public showSottolivelli: boolean = false;
  public userDetectionLayer: __esri.GraphicsLayer = null;
  public currentMap: { map: __esri.Map; mapView: __esri.MapView; };

  get layer(): server.layerFull {
    if (!this.layerData) { return null; }
    return layersStoreGetters.getLayer(this.layerData.surveyId, this.layerData.layerId);
  }

  public async mounted() {
    this.currentMap = await MapHelper.getCurrentMap();
    this.userDetectionLayer = await MapHelper.GetLayerByName("user-detection-layer") as __esri.GraphicsLayer;
    if (!this.userDetectionLayer) {
      this.userDetectionLayer = (await Esri.Layers.GraphicsLayer({ id: "user-detection-layer", name: "user-detection-layer", title: "User Detection Position", listMode: "hide", legendEnabled: false }));
      await MapHelper.addLayer(this.userDetectionLayer);
    }


    this.currentMap.mapView.popup.on("trigger-action", (event) => {
      if (event.action.id === "close") {
        this.userDetectionLayer.removeAll();
      }
    });
  }

  get subLayers(): server.layerFull[] {
    if (!this.layer || (this.layer.subLayers || []).length === 0) { return []; }
    return layersStoreGetters.getLayers(this.layer.surveyId).filter((f) => this.layer.subLayers.indexOf(f.id) !== -1) || [];
  }

  get tableSubLayers(): server.layerFull[] {
    if (!this.subLayers || (this.subLayers || []).length === 0) { return []; }
    return this.subLayers.filter((f) => !f.geometryType) || [];
  }

  get userUpdate() {
    return usersStoreGetters.getUser(this.layerData.lastUpdateUserId) || {};
  }

  get dataArray() {
    const result: Array<{ visible: boolean, name: string, value: string, latitude: any, longitude: any }> = [];
    if (!this.layerData || !this.layerData.jsonData) { return result; }

    const fields = this.layer.definitions.sort((a, b) => {
      if (a.order < b.order) { return -1; }
      if (a.order > b.order) { return 1; }
      return 0;
    }).map((m) => m.fieldName).filter((f) => f.indexOf("_missing") < 0 && f.indexOf("coord") < 0);

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const field = fields[key];
        const elem = (this.layerData.jsonData || {})[field];
        result.push({
          name: field,
          value: (elem !== undefined && elem !== null ? elem : "").toString(),
          visible: (elem !== undefined && elem !== null ? elem : "").toString() !== "",
          latitude: null,
          longitude: null,
        });
      }
    }
    return result.concat(this.detectionPosition);
  }

  get detectionPosition() {
    const result: Array<{ visible: boolean, name: string, value: string, latitude: any, longitude: any }> = [];
    if (!this.layerData || !this.layerData.jsonData) { return result; }

    const fields = this.layer.definitions.sort((a, b) => {
      if (a.order > b.order) { return -1; }
      if (a.order < b.order) { return 1; }
      return 0;
    }).map((m) => m.fieldName).filter((f) => f.indexOf("coord") >= 0);

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const field = fields[key];
        const elem = (this.layerData.jsonData || {})[field];
        result.push({
          name: field,
          value: (elem !== undefined && elem !== null ? elem : "").toString(),
          visible: (elem !== undefined && elem !== null ? elem : "").toString() !== "",
          latitude: null,
          longitude: null,
        });
      }
    }

    this.addUserPositionPointOnMap(result);
    
    let x = null;
    if(result.length){
      x = (result || []).reduce((a, b) => {
        return {
          name: "detection_position",
          value: (a.value + ", " + b.value),
          visible: true,
          latitude: b.value,
          longitude: a.value,
        };
      });
    }else{
      x = {
        name: "detection_position",
        value: null,
        visible: false,
        latitude: null,
        longitude: null,
      };
    }
 
    return x;
  }

  public async centerMap(latitude, longitude) {
    const geom = await Esri.Geometry.Point({ longitude: parseFloat(longitude), latitude: parseFloat(latitude), spatialReference: { wkid: 4326 } } as __esri.PointProperties);
    return this.currentMap.mapView.goTo(geom, { animate: true });
  }

  @Watch("layerData")
  public async layerDataChange() {
    this.userDetectionLayer.removeAll();

    this.typeId = this.layerData.gid;
    this.mediaIds = (await mediasService.ids("layerData", this.typeId)) || [];
    await Promise.all(
      this.mediaIds.map(async (m) => await mediasService.getMedia(m)),
    );

    if (this.tableSubLayers && this.tableSubLayers.length > 0) {
      this.showSottolivelli = false;
      await Promise.all(this.tableSubLayers.map(async (f) => {
        this.relatedLayerDataCounter[f.id] = await this.countRelatedLayerData(f);
      }));
      this.showSottolivelli = true;
    }
  }

  public subLayerLenght(layerId) {
    const subLayerData = getStoreState().layerDatas.layerDatas.filter(
      (d) => d.layerId === layerId && d.parentId === this.layerData.gid,
    );
    if (!subLayerData) { return null; }
    return subLayerData.length;
  }

  public layerName(layerId: string) {
    return (
      getStoreState().layers.layers.find(
        (l) => l.surveyId === this.layerData.surveyId && l.id === layerId,
      ) || ({} as server.layerFull)
    ).name;
  }

  public media(mediaId: string) {
    return `data:image/jpeg;base64,${mediasStoreGetters.getMedia(mediaId)}`;
  }

  public openMedia(mediaId: string) {
    const image = new Image();
    image.src = this.media(mediaId);

    const w = window.open("");
    w.document.write(image.outerHTML);
  }

  public openRelatedLayerData(sublayer: server.layerFull) {
    ModalHelper.ShowModalAsync(LayerDatasViewModal, { layer: sublayer, boundaryId: this.layerData.boundaryId, parentId: this.layerData.gid }).then(() => null, () => null);
  }

  public async countRelatedLayerData(subLayer: server.layerFull): Promise<number> {
    const relatedLayerData = await layerDatasService.GetLayerDatas(subLayer.surveyId, subLayer.id, this.layerData.boundaryId, this.layerData.gid);
    return (relatedLayerData.filter((f) => f.parentId === this.layerData.gid) || []).length;
  }

  public localizeFunction(value: number | boolean | string) {
    if (value !== null && value !== "") {
      return localizeFunction(value.toString());
    }
    return value;
  }

  public manageIssue() {
    const task = tasksStoreGetters.getTasks(this.layerData.boundaryId).filter((f) => f.status !== server.TasksEnum.completed) || [];
    if (task.length > 0) {
      const obj = {
        layerId: this.layerData.layerId,
        layerDataId: this.layerData.gid,
        boundaryId: this.layerData.boundaryId,
        creationDate: new Date(),
        latitude: this.layerData.latitude,
        longitude: this.layerData.longitude,
        surveyId: this.layerData.surveyId,
      } as server.issue;
      ModalHelper.ShowModalAsync(IssueManage, obj, "modal-issue").then(async (u) => {
        AlertHelper.showInfo(localizeFunction("save_issue_data"), localizeFunction("issue_saved"), 2000);
      }).catch((err) => {
        console.warn(err);
      });
    } else {
      ModalHelper.ShowModalAsync(ModalIssue, { type: "default", title: localizeFunction("save_issue_data"), message: localizeFunction("not_issue_because_task_is_completed") }, "modal-issue");
    }
  }

  get isInvalidated() {
    if (this.layerData)
      return this.layerData.supervisorStatus == server.dataSupervisorStatusEnum.toBeCheckedBySupervisor
    return false
  }

  public invalidateLayerData() {
    const task = tasksStoreGetters.getTasks(this.layerData.boundaryId).filter((f) => f.status !== server.TasksEnum.completed) || [];
    if (task.length > 0) {
      const obj = {
        surveyId: this.layerData.surveyId,
        layerId: this.layerData.layerId,
        gID: this.layerData.gid,
        boundaryId: this.layerData.boundaryId,
        supervisorStatus: server.dataSupervisorStatusEnum.toBeCheckedBySupervisor,
        title: '',
        description: '',
        latitude: this.layerData.latitude,
        longitude: this.layerData.longitude
      } as server.layerdataSupervisor;

      ModalHelper.ShowModalAsync(InvalidateLayerData, obj, "modal-issue").then(async (respose) => {

        AlertHelper.showInfo(localizeFunction("invalidate"), localizeFunction("invalidate_data"), 2000);
        Object.assign(this.layerData, respose);

      }).catch((err) => {
        console.warn(err);
      });
    } else {
      ModalHelper.ShowModalAsync(ModalIssue, { type: "default", title: localizeFunction("invalidate"), message: localizeFunction("not_invalidate_because_task_is_completed") }, "modal-issue");
    }
  }

  public async addUserPositionPointOnMap(result: Array<{ visible: boolean; name: string; value: string; }>) {
    this.userDetectionLayer.removeAll();

    if (result && result.length > 1) {
      // Pin
      const symbol = await Esri.Symbols.PictureMarkerSymbol({
        color: "white",
        height: "30px",
        // type: "picture-marker",
        url: "/assets/imgs/user-pin.png",
        width: "30px",
        yoffset: 12,
      });

      const geometry = await Esri.Geometry.Point({ longitude: parseFloat(result[1].value), latitude: parseFloat(result[0].value), spatialReference: { wkid: 4326 } } as __esri.PointProperties);
      const graphic = await Esri.Graphic({
        geometry,
        attributes: null,
        symbol,
      });
      this.userDetectionLayer.add(graphic);
    }
  }
}
