import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { usersStoreGetters } from "@/store";

@Component({})
export default class TrackingMapPopup extends Vue {
  @Prop()
  public track: ITracking;



  get user() {
    return usersStoreGetters.getUser(this.track.username) || {};
  }

  get dataArray() {
    const result: Array<{ name: string, value: string }> = [];
    if (!this.track || !this.track.jsonData) { return result; }

    const data = JSON.parse(this.track.jsonData);
    for (const key in data) {
      const elem = data[key];
      if(elem !== null && elem !== ""){
        result.push({
          name: key,
          value: elem.toString(),
        });
      }
    }
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }
}
