import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Chrome } from "vue-color";

interface colorRgba { r: number; g: number; b: number; a: number; }

@Component({
  components: {
    "chrome-picker": Chrome,
  },
})
export default class ColorPicker extends Vue {
  @Prop()
  public value: string;

  @Prop()
  public label: string;

  public bindColorPicker: colorRgba = null;

  public displayPicker: boolean = false;

  get defaultColor(): string {
    return "rgba(0, 144, 213,1)";
  }

  private colortext: string = this.value;
  public get colorText(): string {
    return this.colortext || this.defaultColor;
  }
  public set colorText(value: string) {
    this.colortext = value;
  }

  public mounted() {
    this.bindColorPicker = this.stringToRGBA(this.colorText);
  }

  @Watch("colorText")
  public valueWatcher(n, o) {
    if (!n) {
      this.bindColorPicker = this.stringToRGBA(this.defaultColor);
    } else {
      if (n !== o) {
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(n)) {
          this.bindColorPicker = this.hexToRGBA(n);
        } else {
          if (/(rgb)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\)/.test(n)) {
            this.bindColorPicker = this.stringToRGBA(n);
          }
        }
      }
    }
  }

  @Watch("bindColorPicker")
  public colorValueWatcher(color: colorRgba) {
    this.$emit("input", `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`);
  }

  public hexToRGBA(hex) {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      let c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 };
    }
    throw new Error("Bad Hex");
  }

  public stringToRGBA(color: string) {
    if (/(rgb)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\)/.test(color)) {
      const rgba = color.startsWith("rgba") ? color.replace(/^rgba?\(|\s+|\)$/g, "").split(",") : `${color.replace(/^rgb?\(|\s+|\)$/g, "")}, 1`.split(",");
      return { r: parseInt(rgba[0]), g: parseInt(rgba[1]), b: parseInt(rgba[2]), a: (parseFloat(rgba[3]) || 1) };
    }
    throw new Error("Bad RGB or RGBA");
  }

  public pickerUpdate(color) {
    this.colorText = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`;
  }

  public showPicker() {
    document.addEventListener("click", this.documentClick);
    this.displayPicker = true;
  }

  public togglePicker() {
    this.displayPicker ? this.hidePicker() : this.showPicker();
  }

  private hidePicker() {
    document.removeEventListener("click", this.documentClick);
    this.displayPicker = false;
  }

  private documentClick(e: MouseEvent) {
    const el = this.$refs.colorpicker as HTMLElement;
    const target = e.target as Node;
    if(el && el !== target && el.contains && !el.contains(target)) {
      this.hidePicker();
    }
  }
}
