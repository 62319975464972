import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { localizeFunction } from "@/filters/localize";

import ShapeFileParser from "shpjs";
import { MapHelper } from "@/app_modules/MapHelper";
import { Esri, BindArrayToLayer } from "@/esriMap";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";

import { Deferred } from "@/services/_base/Deferred";

import EsriMap from "@/components/map/views/Map.vue";
import LoadMore from "@/components/loadMore/loadMore.vue";

@Component({ components: { EsriMap, LoadMore } })
export default class ShapeImport extends Vue {

  @Prop({ required: false })
  public geometryType: string;

  @Prop({ default: true, required: false })
  public showPreview: boolean;

  @Prop({ default: -1, required: false })
  private previewMaxRows: number

  public shapeFileImportError: string = null;
  public isLoading = false;

  private SHAPE_LAYER: string = "SHAPE_LAYER";
  private layer: Promise<__esri.GraphicsLayer> = new Deferred<__esri.GraphicsLayer>().promise;
  private file: File = null;
  private shapeFile: File = null;

  public async mounted() {
    await MapHelper.WaitMapReady();
    this.layer = Esri.Layers.GraphicsLayer({ id: this.SHAPE_LAYER, name: this.SHAPE_LAYER, title: "Survey", listMode: "show", legendEnabled: false, opacity: 0.8 });

    await MapHelper.addLayer(await this.layer, 9999);
    this.addShapeLayer();
    this.isLoading = true;
  }

  get labelCreateFieldInstructions() {
    return localizeFunction("create_field_instructions");
  }

  private pShapeFeatures: any[] = [];
  get shapeFeatures(): any[] {
    return this.pShapeFeatures;
  }

  set shapeFeatures(values: any[]) {
    this.pShapeFeatures = values;
    if (values && values.length > 0 && this.showPreview) {
      this.centerShapeMap();
    }
  }

  public async uploadShapeFile(event) {
    this.shapeFileImportError = null;
    
    if (event.target.files.length <= 0 || !event.target.files[0]) { return; }

    this.shapeFile = event.target.files[0];
    this.isLoading = true;

    setTimeout(async () => {
      const fileData = await this.readAsArrayBuffer(this.shapeFile);
      try {
        ShapeFileParser(fileData).then((geojsonshp: ShapeFileParser.FeatureCollectionWithFilename) => {
          let rows = [];
          if (!geojsonshp.features && geojsonshp.features.length <= 0) {
            this.skip = 0;
            this.shapeFeatures = [];
            this.features = [];
            this.shapeFileImportError = "features_length_as_empty";
            (this.$refs.shapeFileInput as HTMLInputElement).value = "";
            return;
          }

          // const str = JSON.stringify(geojsonshp);
          // const bytes = new TextEncoder().encode(str);
          // const blob = new Blob([bytes], {
          //   type: "application/json;charset=utf-8"
          // });

          // this.file = new File([blob], this.shapeFile.name, {type: "application/json;charset=utf-8", lastModified: this.shapeFile.lastModified})
          this.file = this.shapeFile;

          if (this.previewMaxRows > 0 && geojsonshp.features.length > this.previewMaxRows) {
            rows = geojsonshp.features.slice(0, this.previewMaxRows);
          } else {
            rows = geojsonshp.features;
          }
  
          const geometryType = this.geometryTypeParse(rows[0]);
          if (this.geometryType && geometryType.indexOf(this.geometryType) < 0) {
            this.skip = 0;
            this.shapeFeatures = [];
            this.features = [];
            this.shapeFileImportError = "error_shape_geometry_type_not_match";
            (this.$refs.shapeFileInput as HTMLInputElement).value = "";
            return;
          }
  
          this.shapeFeatures = rows;
          this.features = this.shapeFeatures.slice(0, this.take).map((feat) => feat.properties) || [];
  
          this.isLoading = false;

        }).catch(error => {
          console.error(error);
          this.skip = 0;
          this.shapeFeatures = [];
          this.features = [];
          this.shapeFileImportError = "error_importing_shape_file";
          (this.$refs.shapeFileInput as HTMLInputElement).value = "";
        });
        

      } catch (error) {
        console.error(error);
        this.skip = 0;
        this.shapeFeatures = [];
        this.features = [];
        this.shapeFileImportError = "error_importing_shape_file";
        (this.$refs.shapeFileInput as HTMLInputElement).value = "";
      }
    }, 200);
  }

  skip: number = 0;
  take: number = 20;

  private pFeatures: any[] = [];
  public get features(): any[] {
    return this.pFeatures;
  }
  public set features(v: any[]) {
    this.pFeatures = this.features.concat(v) || [];
  }

  public onLoadMoreItems() {
    this.skip += this.take;
    this.features = this.shapeFeatures.slice(this.skip, (this.skip + this.take)).map((feat) => feat.properties) || [];
  }

  public confirmShapeFeatures() {
    this.isLoading = true;

    setTimeout(() => {
      this.$emit("confirm", {
        file: this.file,
        filename: this.shapeFile.name,
        filedate: new Date(this.shapeFile.lastModified),
        features: this.shapeFeatures.map((feat) => {
          if (!feat || !feat.properties) {
            feat = { properties: {} };
          }
          feat.properties.geoJSON = feat.geometry;
          return feat.properties;
        }),
      });
      this.skip = 0;
      this.shapeFeatures = [];
      this.features = [];
      (this.$refs.shapeFileInput as HTMLInputElement).value = "";
    }, 200);
  }

  public clearShapeFeatures() {
    this.skip = 0;
    this.shapeFeatures = [];
    this.features = [];
    (this.$refs.shapeFileInput as HTMLInputElement).value = "";
    this.$emit('cancel');
  }

  private async addShapeLayer() {
    const layer = await this.layer;
    if (!layer) { return; }

    await BindArrayToLayer(this.shapeFeatures, layer.graphics, async (feat) => {
      const geometry = await this.geometryESRI(feat);
      const symbol = await this.symbolESRI(feat);
      return await Esri.Graphic({
        geometry,
        symbol,
        attributes: feat.properties,
      } as __esri.GraphicProperties);
    }, true, true, true);
  }

  private async centerShapeMap() {
    await MapHelper.WaitMapReady();

    if (!await this.layer) { return; }

    const engine = await Esri.Geometry.geometryEngine();
    const geometries: __esri.Geometry[] = [];

    for (const item in this.shapeFeatures) {
      if (this.shapeFeatures.hasOwnProperty(item)) {
        const feat = this.shapeFeatures[item];
        geometries.push(await this.geometryESRI(feat));
      }
    }

    if (geometries && geometries.length > 0) {
      MapHelper.goToGeometry(engine.union(geometries));
    }
  }

  private async geometryESRI(feat) {
    const geometryType = this.geometryTypeParse(feat);

    if (geometryType === "point") {
      return await Esri.Geometry.Point(geojsonToArcGIS(feat.geometry));
    }

    if (geometryType === "polygon" || geometryType === "multipolygon") {
      return await Esri.Geometry.Polygon(geojsonToArcGIS(feat.geometry));
    }

    return null;
  }

  private geometryTypeParse(feat: any) {
    return feat && feat.geometry && feat.geometry.type ? (feat.geometry.type as string).toLowerCase() : "_not_defined_";
  }

  private async symbolESRI(feat) {
    const geometryType = feat
      && feat.geometry
      && feat.geometry.type ? (feat.geometry.type as string).toLowerCase()
      : "_not_defined_";

    if (geometryType === "point") {
      return {
        type: "simple-marker",
        color: [0, 144, 213, 0.8],
        outline: { color: [0, 144, 180], width: 2 },
      };
    }

    if (geometryType === "polygon" || geometryType === "multipolygon") {
      return {
        type: "simple-fill",
        color: [0, 144, 213, 0.8],
        outline: { color: [0, 144, 180], width: 2 },
      };
    }

    return null;
  }

  private async readAsArrayBuffer(file: File) {
    return new Promise<any>((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        res(e.target.result);
      };
      reader.onabort = (e) => {
        rej(e);
      };
      reader.onerror = (e) => {
        rej(e);
      };
      reader.readAsArrayBuffer(file);

    });
  }
}
