import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { createSurveyStoreActions, createSurveyStoreGetters, layersStoreGetters } from "@/store";

import { CloneObject } from "@/utils";

@Component({})
export default class ManageValidationRules extends Vue {
    @Prop({ required: true })
    public layer: server.layerFull;

    @Prop({ required: true })
    public definition: server.layerDefinitionFull;

    @Prop({ default: false })
    public readonly: boolean;

    public relatedDefinition: server.layerDefinitionFull = null;

    public parentDefinition: server.layerDefinitionFull = null;

    public manage: server.layerDefinitionValidationRule = null;

    // (1) Required, // Obbligatorio, vale per tutti, non richiede campi ulteriori

    //// Richiedono un valore del campo a seconda del dataType della definizion
    // (3) GreaterThan, //Per campi numerici
    // (4) LesserThan,  //Per campi numerici
    // (5) NoChildsIfValue, //Blocca l'aggiunta di LayerData, relativi al Layer figlio, quando questo campo presenti uno specifico valore
    // (6) DisableValueForFirstItem, //Implica che il valore non può essere inserito per il primo LayerData (es. la prima entrata non può essere DoubleEntrance)

    private fieldRoleTypes: any[] = [
        /*1*/ { value: "required", key: "Required", type: [], parentRequired: false },
        /*2*/ { value: "required_if_field_value", key: "RequiredIfFieldValue", type: [], parentRequired: false },
        /*3*/ { value: "greater_than", key: "GreaterThan", type: ["number"], parentRequired: false },
        /*4*/ { value: "less_or_equal_than", key: "LesserThan", type: ["number"], parentRequired: false },
        /*5*/ { value: "no_child_if_value", key: "NoChildsIfValue", type: [], parentRequired: false },
        /*6*/ { value: "disable_for_first_item", key: "DisableValueForFirstItem", type: [], parentRequired: false },
        /*7*/ { value: "can_miss_data_if_field_value", key: "CanMissDataIfFieldValue", type: [], parentRequired: false },
        /*8*/ { value: "hide_if_parent_field_value", key: "HideIfParentFieldValue", type: [], parentRequired: true },
        /*9*/ { value: "required_if_parent_field_value", key: "RequiredIfParentFieldValue", type: [], parentRequired: true },
        /*10*/{ value: "greater_then_parent_field_value", key: "GreaterThanParentFieldValue", type: ["number"], parentRequired: true },
        /*11*/{ value: "lesser_than_parent_field_value", key: "LesserThanParentFieldValue", type: ["number"], parentRequired: true },
    ];

    //// Richiedono il relatedDefinition
    // (2) RequiredIfFieldValue
    // (7) CanMissDataIfFieldValue
    get requireRelatedDefinition() {
        return this.manage && (this.manage.validationType === "RequiredIfFieldValue"
            || this.manage.validationType === "CanMissDataIfFieldValue");
    }

    //// Richiedono il requireRelatedParentDefinition cioè i dati relativi alla relazione con il layer padre
    // (8) HideIfParentFieldValue
    // (9) RequiredIfParentFieldValue
    // (10) GreaterThanParentFieldValue
    // (11) LesserThanParentFieldValue
    get requireRelatedParentDefinition() {
        return this.manage && (this.manage.validationType === "HideIfParentFieldValue"
            || this.manage.validationType === "RequiredIfParentFieldValue"
            || this.manage.validationType === "GreaterThanParentFieldValue"
            || this.manage.validationType === "LesserThanParentFieldValue");
    }

    get requireRelatedParentDefinitionValue() {
        return this.manage && (this.manage.validationType === "HideIfParentFieldValue"
            || this.manage.validationType === "RequiredIfParentFieldValue");
    }

    public beforeDestroy() {
        this.clearValidationRule();
    }

    get availableFieldTypes() {
        let fieldTypes = this.fieldRoleTypes.filter((f) => !f.parentRequired);
        if (this.parentDefinitions.length > 0) {
            fieldTypes = fieldTypes.concat(this.fieldRoleTypes.filter((f) => !!f.parentRequired));
        }
        const type = this.definition.dataType;
        return fieldTypes.filter((f) => (f.type.length === 0 || (f.type.indexOf(type) >= 0)));
    }

    get relatedDefinitions(): string[] {
        return (this.layer.definitions || []).map((x) => x.fieldName);
    }

    get parentDefinitions(): string[] {
        const parentLayer = layersStoreGetters.getLayers(this.layer.surveyId).find((f) => f.subLayers.indexOf(this.layer.id) !== -1);
        if (parentLayer) {
            if (this.manage.validationType === "GreaterThanParentFieldValue" || this.manage.validationType === "LesserThanParentFieldValue") {
                return (parentLayer.definitions || []).filter(f => f.dataType === "number").map((x) => x.fieldName);
            } else {
                return (parentLayer.definitions || []).map((x) => x.fieldName);
            }
        }
        return [];
    }

    get validRole(): boolean {
        if (!this.manage) { return false; }
        if (this.manage.order < 0) { return false; }
        if (this.manage.validationType !== "Required" && (this.manage.validationRule === null || this.manage.validationRule === undefined)) { return false; }
        if ((this.manage.validationType === "CanMissDataIfFieldValue" || this.manage.validationType === "HideIfParentFieldValue" || this.manage.validationType === "RequiredIfFieldValue") 
            && (this.manage.validationFieldValue === null || this.manage.validationFieldValue === undefined)) { return false; }
        return true;
    }

    @Watch("manage.validationType")
    public validationTypeWatcher(value) {
        if (this.manage) {
            this.manage.validationRule = null;
            this.manage.validationFieldValue = null;
        }
    }

    @Watch("manage.validationRule")
    public validationRuleWatcher(value) {
        if (this.manage) {
            if (!value) {
                this.relatedDefinition = null;
                this.parentDefinition = null;
                return;
            }

            this.relatedDefinition = this.layer.definitions.find((f) => f.fieldName === this.manage.validationRule);
            const p = layersStoreGetters.getLayers(this.layer.surveyId).find((f) => f.subLayers.indexOf(this.layer.id) !== -1);
            if (p) {
                this.parentDefinition = (p.definitions || []).find((f) => f.fieldName === this.manage.validationRule);
            }
        }
    }

    get availableReleatedFieldValues(): string[] {
        if (!this.relatedDefinition) { return []; }
        return this.relatedDefinition.jsonData;
    }

    get availableParentFieldValues(): string[] {
        if (!this.parentDefinition) { return []; }
        return this.parentDefinition.jsonData;
    }

    get validationRules() {
        return createSurveyStoreGetters.getValidationRules(this.definition);
    }

    public addValidationRule() {
        const rule: server.layerDefinitionValidationRule = {
            id: 0,
            layerDefinitionId: this.definition.id,
            layerId: this.definition.layerId,
            order: this.validationRules.length,
            surveyId: this.definition.surveyId,
            validationType: server.validationTypes.required,
            validationFieldValue: null,
            validationRule: null,
        };
        this.manage = rule;
    }

    public clearValidationRule() {
        this.manage = null;
    }

    public saveValidationRule() {
        createSurveyStoreActions.setValidationRule(this.manage);
        this.clearValidationRule();
    }

    public removeValidationRule(rule: server.layerDefinitionValidationRule) {
        createSurveyStoreActions.removeValidationRule(rule);
    }
}
