import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { localizeFunction } from "@/filters/localize";

import { MapHelper } from "@/app_modules/MapHelper";
import { Esri, BindArrayToLayer } from "@/esriMap";
import { geojsonToArcGIS } from "@esri/arcgis-to-geojson-utils";

import ShapeFileParser from "shpjs";

import { Deferred } from "@/services/_base/Deferred";

import EsriMap from "@/components/map/views/Map.vue";
import LoadMore from "@/components/loadMore/loadMore.vue";

@Component({ components: { EsriMap, LoadMore } })
export default class GeoJsonImport extends Vue {

  @Prop({ required: false })
  public geometryType: string;

  @Prop({ default: true, required: false })
  public showPreview: boolean;

  @Prop({ default: -1, required: false })
  private previewMaxRows: number

  public geoJsonFileImportError: string = null;
  public isLoading = false;

  private SHAPE_LAYER: string = "GEOJSON_LAYER";
  private layer: Promise<__esri.GraphicsLayer> = new Deferred<__esri.GraphicsLayer>().promise;
  private file: File = null;

  public async mounted() {
    await MapHelper.WaitMapReady();
    this.layer = Esri.Layers.GraphicsLayer({ id: this.SHAPE_LAYER, name: this.SHAPE_LAYER, title: "Survey", listMode: "show", legendEnabled: false, opacity: 0.8 });

    await MapHelper.addLayer(await this.layer, 9999);
    this.addGeoJsonLayer();
    this.isLoading = true;
  }

  get labelCreateFieldInstructions() {
    return localizeFunction("create_field_instructions");
  }

  private geoJsonFeatures: any[] = [];

  public async uploadGeoJsonFile(event) {
    this.geoJsonFileImportError = null;
    this.file = event.target.files[0];
    if (!this.file) { return; }

    this.isLoading = true;

    setTimeout(async () => {
      const fileData: ShapeFileParser.FeatureCollectionWithFilename = await this.readAsArrayBuffer(this.file);
      let rows = [];
      try {
        if (!fileData.features && fileData.features.length <= 0) {
          this.skip = 0;
          this.geoJsonFeatures = [];
          this.features = [];
          this.geoJsonFileImportError = "features_length_as_empty";
          (this.$refs.geoJsonFileInput as HTMLInputElement).value = "";
          return;
        }

        if (this.previewMaxRows > 0 && fileData.features.length > this.previewMaxRows) {
          rows = fileData.features.slice(0, this.previewMaxRows);
        } else {
          rows = fileData.features;
        }

        const geometryType = this.geometryTypeParse(rows[0]);
        if (this.geometryType && geometryType.indexOf(this.geometryType) < 0) {
          this.skip = 0;
          this.geoJsonFeatures = [];
          this.features = [];
          this.geoJsonFileImportError = "error_geoJson_geometry_type_not_match";
          (this.$refs.geoJsonFileInput as HTMLInputElement).value = "";
          return;
        }

        this.geoJsonFeatures = rows;
        this.features = this.geoJsonFeatures.slice(0, this.take).map((feat) => feat.properties) || [];
        this.isLoading = false;

      } catch (error) {
        console.error(error);
        this.skip = 0;
        this.geoJsonFeatures = [];
        this.features = [];
        this.geoJsonFileImportError = "error_importing_geoJson_file";
        (this.$refs.geoJsonFileInput as HTMLInputElement).value = "";
      }
    }, 200);
  }

  skip: number = 0;
  take: number = 20;


  private pFeatures: any[] = [];
  public get features(): any[] {
    return this.pFeatures;
  }
  public set features(v: any[]) {
    this.pFeatures = this.features.concat(v) || [];
  }

  public onLoadMoreItems() {
    this.skip += this.take;
    this.features = this.geoJsonFeatures.slice(this.skip, (this.skip + this.take)).map((feat) => feat.properties) || [];
  }

  public confirmGeoJsonFeatures() {
    this.isLoading = true;

    setTimeout(() => {
      this.$emit("confirm", {
        file: this.file,
        filename: this.file.name,
        filedate: new Date(this.file.lastModified),
        features: this.geoJsonFeatures.map((feat: any) => {
          if (!feat || !feat.properties) {
            feat = { properties: {} };
          }
          feat.properties.geoJSON = feat.geometry;
          return feat.properties;
        }),
      });
      this.skip = 0;
      this.geoJsonFeatures = [];
      this.features = [];
      (this.$refs.geoJsonFileInput as HTMLInputElement).value = "";
    }, 200);
  }

  public clearGeoJsonFeatures() {
    this.skip = 0;
    this.geoJsonFeatures = [];
    this.features = [];
    (this.$refs.geoJsonFileInput as HTMLInputElement).value = "";
    this.$emit('cancel');
  }

  private async addGeoJsonLayer() {
    const layer = await this.layer;
    if (!layer) { return; }

    await BindArrayToLayer(this.geoJsonFeatures, layer.graphics, async (feat) => {
      const geometry = await this.geometryESRI(feat);
      const symbol = await this.symbolESRI(feat);
      return await Esri.Graphic({
        geometry,
        symbol,
        attributes: feat.properties,
      } as __esri.GraphicProperties);
    }, true, true, true);
  }

  private async centerGeoJsonMap() {
    await MapHelper.WaitMapReady();

    if (!await this.layer) { return; }

    const engine = await Esri.Geometry.geometryEngine();
    const geometries: __esri.Geometry[] = [];

    for (const item in this.geoJsonFeatures) {
      if (this.geoJsonFeatures.hasOwnProperty(item)) {
        const feat = this.geoJsonFeatures[item];
        geometries.push(await this.geometryESRI(feat));
      }
    }

    if (geometries && geometries.length > 0) {
      MapHelper.goToGeometry(engine.union(geometries));
    }
  }

  private async geometryESRI(feat) {
    const geometryType = this.geometryTypeParse(feat);

    if (geometryType === "point") {
      return await Esri.Geometry.Point(geojsonToArcGIS(feat.geometry));
    }

    if (geometryType === "polygon" || geometryType === "multipolygon") {
      return await Esri.Geometry.Polygon(geojsonToArcGIS(feat.geometry));
    }

    return null;
  }

  private geometryTypeParse(feat: any) {
    return feat && feat.geometry && feat.geometry.type ? (feat.geometry.type as string).toLowerCase() : "_not_defined_";
  }

  private async symbolESRI(feat) {
    const geometryType = feat
      && feat.geometry
      && feat.geometry.type ? (feat.geometry.type as string).toLowerCase()
      : "_not_defined_";

    if (geometryType === "point") {
      return {
        type: "simple-marker",
        color: [0, 144, 213, 0.8],
        outline: { color: [0, 144, 180], width: 2 },
      };
    }

    if (geometryType === "polygon" || geometryType === "multipolygon") {
      return {
        type: "simple-fill",
        color: [0, 144, 213, 0.8],
        outline: { color: [0, 144, 180], width: 2 },
      };
    }

    return null;
  }

  reader = new FileReader();
  private async readAsArrayBuffer(file: File) {
    return new Promise<any>((res, rej) => {
      const reader = this.reader;
      reader.onload = async (e: any) => {
        if (!e.target.result) {
          rej(e);
        } else {
          res(JSON.parse(e.target.result));
        }
      };
      reader.onabort = (e) => {
        rej(e);
      };
      reader.onerror = (e) => {
        rej(e);
      };
      reader.readAsText(file);
    });
  }
}
