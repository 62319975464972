import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { issuesService, mediasService } from "@/services";
import { surveysStoreGetters, usersStoreGetters, mediasStoreActions, mediasStoreGetters, getStoreState } from "@/store";
import { ModalHelper } from "@/components";
import { Deferred } from "@/services/_base/Deferred";
import { AlertHelper } from "@/services/_base/AlertHelper";
import IssueCard from "./issueCard.vue";
import { localizeFunction } from "@/filters/localize";
import { uuidv4 } from "@/main";

@Component({ components: { IssueCard } })
export default class IssueManage extends Vue {
  @Prop()
  public deferred: Deferred<server.issue>;

  @Prop({ required: true })
  public value: server.issue;

  public manage: server.issue = {} as server.issue;

  public srcToExpand: any = null;
  public showModalImage: boolean = false;
  public medias: server.media[] = [];

  get isAdmin() {
    return usersStoreGetters.currentUser().isAdmin;
  }

  public mounted() {
    this.valueChange(this.value);
  }

  @Watch("value")
  public valueChange(value) {
    const valueChanged = value || {} as server.issue;
    if (this.manage && value.id && this.manage.id !== valueChanged.id) {
      this.getMedias(value.id);
    }
    this.manage = Object.assign({}, valueChanged);
  }

  public reset() {
    this.valueChange(this.value);
  }

  public abort() {
    ModalHelper.HideModal("modal-issue");
    if (this.deferred) { this.deferred.reject(); }
  }

  public async reject() {
    this.manage.state = server.IssueStatus.rejected;
    this.save();
  }

  public async resolve() {
    this.manage.state = server.IssueStatus.resolved;
    this.save();
  }

  public async save() {
    const issue = await issuesService.PutIssue(
      surveysStoreGetters.currentSurvey().id,
      this.manage.id,
      this.manage,
    );

    AlertHelper.showInfo("edit_issue", "issue_saved");
    ModalHelper.HideModal("modal-issue");
    if (this.deferred) { this.deferred.resolve(issue); }
  }

  public async add() {
    this.manage.id = uuidv4();
    const issue = await issuesService.PostIssue(
      surveysStoreGetters.currentSurvey().id,
      this.manage,
    );

    AlertHelper.showInfo("edit_issue", "issue_saved");
    ModalHelper.HideModal("modal-issue");
    if (this.deferred) { this.deferred.resolve(issue); }
  }

  public image(data) {
    return "data:image/jpeg;base64," + data + "";
  }

  public async getMedias(issueId: string) {
    if (!issueId) { return; }

    const mediaIds = await mediasService.ids("issue", issueId);

    for (const key in mediaIds) {
      if (mediaIds.hasOwnProperty(key)) {
        const mediaId = mediaIds[key];
        const stored = await mediasStoreGetters.getMedia(mediaId);
        if (!stored) {
          await mediasService.getMedia(mediaIds[key]);
        }
      }
    }
    this.medias = await mediasStoreGetters.getMedias("issue", issueId);
  }

  public openImagePopup(src) {
    this.srcToExpand = src;
    this.showModalImage = true;
  }

  public closePopup() {
    this.showModalImage = false;
  }

  public getUserData(issue: server.issue) {
    const user = getStoreState().users.users.find((u) => u.userName === issue.userName);
    if (user) {
      return `${user.description} (${this.removeAppPrefix(user.userName)})`;
    }
    return localizeFunction("not_assigned");
  }

  public removeAppPrefix(email: string) {
    return email.replace(/.*::/g, "");
  }
}
