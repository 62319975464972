import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class Autocomplete extends Vue {
  @Prop({ default: {} })
  value: server.autocomplete;

  @Prop({ default: [] })
  suggestions: Array<server.autocomplete>;

  @Prop({ default: 0 })
  minLength: number;

  @Prop({ default: false })
  startWith: boolean;

  @Prop({  default: true })
  clearable: boolean

  @Prop({ default: true })
  forceSelect: boolean

  @Prop({ default: "form-control" })
  inputClass: string;

  searchText: string = ((this.value || {} as server.autocomplete).description || "");
  show: boolean = false;
  
  highlight: number = -1;
  _selecting: boolean = false;

  @Watch("value")
  valueWatcher(n: server.autocomplete, o: server.autocomplete) {
    if (n && n !== o) {
      this.searchText = (n || {} as server.autocomplete).description || "";
    }
  }

  @Watch("searchText")
  searchTextWatcher(n: string, o: string) {
    if (n && n !== o) {
      this.toggle(n.length >= this.minLength);
    }
  }

  get orderedSuggesions(){
    return this.suggestions.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
  }

  get filtered() {
    // let rgxp = new RegExp(`(?=(<before>.*?))(?=(<search>${this.searchText}))(?:(<after>.*))`, "i");
    let rgxp = new RegExp(`(.*)(${this.searchText})(.*)`, "i")
    return this.orderedSuggesions
      .filter(s => {
        if (this.startWith)
          return s.description.toLowerCase().startsWith((this.searchText || "").toLowerCase()); //filtro i termini della lista che iniziano con l'input dell'utente
        return s.description.toLowerCase().indexOf((this.searchText || "").toLowerCase()) > -1; //filtro i termini della lista che contengono l'input dell'utente
      })
      .map(r => {
          let match = r.description.match(rgxp) as any || {};
          match = match && match ? { before: match[1], search: match[2], after: match[3] } : { before: "", search: "", after: "" };
          match.item = r;
          return match;
      });
  }

  get showSuggestions() {
    return this.show && this.filtered.length; //mostro i suggerimenti se richiesto esplicitamente e se ci sono elementi dal filtro
  }

  get ifClearable(){
    return this.clearable && this.value && this.value && this.value.key != undefined;
  }

  clear(){
    this.searchText = "";
    this.$emit("input", {});
  }

  toggle(v) {
    //richiedo esplicitamente l'apertura/chiusura dei suggerimenti... ma solo se l'input è attivo e scrivibile
    if (v && (this.$attrs.readonly || this.$attrs.disabled)) return;
    if(!v && this.forceSelect && this.filtered.length <= 0 && (!this.value || (this.value && (!this.value.key || this.value.description != this.searchText)))) 
    {
      this.clear();
    }
    this.show = v;
    this.highlight = -1;
  }

  handlersToggle(v) {
    if (!this._selecting && this.searchText.length >= this.minLength) this.toggle(v);
  }

  selected() {
    if (this.filtered[this.highlight])
    {
      let item = this.filtered[this.highlight].item;
      this.searchText = item.description;
      this.$emit("input", item);
    }
    this.show = false;
    this._selecting = false;
  }

  selecting(index) {
    this._selecting = index != null;
    this.highlight = index;
  }

  goUp() {
    if (this.show) {
      this.highlight = Math.max(this.highlight - 1, 0);
    }
  }

  goDown() {
    if (this.show) {
      this.highlight = Math.min(this.highlight + 1, this.filtered.length - 1);
    }
  }

  selectByEnrter() {
    if (this.show) {
      this.selected();
    }
  }

  created() {
    this.$validator = this.$parent.$validator;
    this.$validator.validateAll();
  }

  beforeMount() {
    this.$emit("input", this.value);
  }
}
