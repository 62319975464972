import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ReportBadge extends Vue {
  @Prop({ required: true })
  public title: string;

  @Prop({ required: true })
  public values: any[];

  @Prop({ required: false, default: true })
  public loading: boolean;

  @Prop({ required: false })
  public date?: Date;
}
