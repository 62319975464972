import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ColorPicker from "@/components/color-picker/color-picker.vue";
import { CloneObject } from "@/utils";

@Component({ components: { ColorPicker } })
export default class LayerColorPicker extends Vue {
  @Prop()
  public value: any;

  @Prop({default: "simple-fill"})
  public type: string;

  @Prop({default: "default"})
  public size: string;

  private defaultFillColor = [0, 144, 180, 0.8];
  private defaultOutlineColor = [0, 144, 180, 1];

  get symbol() {
    return CloneObject(this.value || this.defaultValue);
  }

  get defaultValue() {
    const symbol = {
      type: this.type,
      color: this.defaultFillColor,
      outline: { color: this.defaultOutlineColor, width: 2 }
    } as any;

    if (this.isPoint) {
      symbol.type = this.type;
    }

    return symbol;
  }

  get isPoint() {
    return this.type === "simple-marker";
  }

  private fillcolor: string = null;
  public get fillColor(): string {
    if (this.fillcolor) {
      return this.fillcolor;
    }
    const color = this.symbol.color;
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${(color[3] || 1)})`;
  }
  public set fillColor(color: string) {
    this.fillcolor = color;
    this.symbol.color = this.rgbaToArray(color);
  }

  private outlinecolor: string = null;
  public get outlineColor(): string {
    if (this.outlinecolor) {
      return this.outlinecolor;
    }
    const color = this.symbol.outline.color;
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${(color[3] || 1)})`;
  }
  public set outlineColor(color: string) {
    this.outlinecolor = color;
    this.symbol.outline.color = this.rgbaToArray(color);
  }

  @Watch("fillColor")
  @Watch("outlineColor")
  public symbolChangeWatcher(){
    this.$emit("input", this.symbol);
  }

  private rgbaToArray(color: string) : number[] {
    if (/(rgb)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\)/.test(color)) {
      const rgba = color.startsWith("rgba") ? color.replace(/^rgba?\(|\s+|\)$/g,'').split(',') : `${color.replace(/^rgb?\(|\s+|\)$/g,'')}, 1`.split(',');
      return [parseInt(rgba[0]), parseInt(rgba[1]), parseInt(rgba[2]), (parseFloat(rgba[3]) || 1)];
    }
    throw new Error('Bad RGB or RGBA');
  }
}
